@font-face {
  font-family: "Futura";
  src: url("./fonts/SansSerifBookFLF.otf");
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 575.98px) {
  .lower-cross {
    margin-top: 16%;
  }

  .product-card .product-details h5 {
    font-size: 14px !important;
  }

  .store-sort {
    font-size: 13px !important;
  }

  .totalproducts {
    font-size: 13px !important;
  }

  .cart-price {
    font-size: 10px !important;
  }

  .cart-item-data p {
    font-size: 10px !important;
  }
}


@media (min-width: 576px) and (max-width: 767.98px) {
  .lower-cross {
    margin-top: 15%
  }

  .product-card .product-details h5 {
    font-size: 15px !important;
  }

  .store-sort {
    font-size: 14px !important;
  }

  .totalproducts {
    font-size: 14px !important;
  }

  .cart-item-data p {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .lower-cross {
    margin-top: 13%
  }

  .store-sort {
    font-size: 15px !important;
  }

  .totalproducts {
    font-size: 15px !important;
  }

  .cart-item-data p {
    font-size: 14px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .lower-cross {
    margin-top: 11%
  }

  .store-sort {
    font-size: 16px !important;
  }

  .totalproducts {
    font-size: 16px !important;
  }

  .cart-item-data p {
    font-size: 14px !important;
  }
}


@media (min-width: 1200px) {
  .lower-cross {
    margin-top: 9.5%
  }

  .store-sort {
    font-size: 16px !important;
  }

  .totalproducts {
    font-size: 16px !important;
  }

  .cart-item-data p {
    font-size: 14px !important;
  }
}

/* Mobile Responsive  */
/* Extra small devices (phones) */
@media (max-width: 575.98px) {

  .featured-wrapper {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .special-wrapper {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .popular-wrapper {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .section-heading {
    font-size: 20px !important;
    margin-bottom: 4%;
  }

  .review-heading {
    font-size: 20px !important;
  }
}

/* Small devices (landscape phones) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* .featured-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .special-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  } */

  .popular-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .section-heading {
    font-size: 24px !important;
    margin-bottom: 20px;
  }

  .review-heading {
    font-size: 24px !important;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* .featured-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .special-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  } */

  .popular-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .section-heading {
    font-size: 24px !important;
    margin-bottom: 20px;
  }

  .review-heading {
    font-size: 24px !important;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* .featured-wrapper {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .special-wrapper {
    padding-top: 22px;
    padding-bottom: 22px;
  } */

  .popular-wrapper {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .section-heading {
    font-size: 28.2px !important;
    margin-bottom: 30px;
  }

  .review-heading {
    font-size: 28.2px !important;
  }
}

/* Extra large devices (large desktops) */
@media (min-width: 1200px) {
  /* .featured-wrapper {
    padding-top: 38px;
    padding-bottom: 26px;
  }

  .special-wrapper {
    padding-top: 38px;
    padding-bottom: 26px;
  } */

  .popular-wrapper {
    padding-top: 38px;
    padding-bottom: 26px;
  }

  .section-heading {
    font-size: 30.6px !important;
    margin-bottom: 45px;
  }

  .review-heading {
    font-size: 30.6px !important;
  }
}





/* Mobile Responsive  */





img {
  -webkit-user-drag: none;
  /* Disable dragging for webkit browsers */
  -khtml-user-drag: none;
  /* Disable dragging for khtml browsers */
  -moz-user-drag: none;
  /* Disable dragging for mozilla browsers */
  -o-user-drag: none;
  /* Disable dragging for opera browsers */
  user-drag: none;
  /* Disable dragging for all browsers */
}

body {
  /* font-family: "Twentieth Century", sans-serif; */
  -webkit-user-select: none;
  /* Disable selection for webkit browsers */
  -moz-user-select: none;
  /* Disable selection for mozilla browsers */
  -ms-user-select: none;
  /* Disable selection for IE */
  user-select: none;
  /* Disable selection for all browsers */
}

/* Add more styling as needed */
.user-menu {
  position: relative;
  /* Ensure the dropdown menu is positioned relative to this container */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: -100px;
  z-index: 9;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  /* Add more styling as needed */
}

.dropdown-menu li {
  margin-bottom: 5px;
}

.dropdown-menu li:last-child {
  margin-bottom: 0;
}

/* Styling for the button/link inside the dropdown menu */
.dropdown-menu a {
  color: #000;
  text-decoration: none;
}

/* Hide the dropdown by default */
.dropdown-menu {
  display: none;
}

/* Show the dropdown when the parent is clicked */
.user-menu:hover .dropdown-menu {
  display: flex;
}



/* test  */

.my-menu-class-light .dropdown-menu {
  background-color: white !important;
  color: black;
  border: 1px solid #ced4da;
  height: 170px;
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}

.my-menu-class-dark .dropdown-menu {
  background-color: inherit;
  border: 1px solid #ced4da;
  color: white;
  height: 170px;
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}

.my-menu-class-dark.input-group {
  justify-content: end;
}

.my-menu-class-light.input-group {
  justify-content: end;
}

/* test  */





.error {
  color: red;
  font-size: 12px
}

:root {
  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: #ffea00;
  --secondary-dark: #e3d002;
  --overlay: rgba(0, 0, 0, .7);
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-ffffff: #ffffff;
  --color-bf4800: #bf4800;
  --color-232f3e: #232f3e;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-1c1c1b: #1c1c1b;
  --color-777777: #777777;
  --color--f85606: #f85606
}

.card-wrapper {
  background-color: var(--color-ffffff);
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

a {
  text-decoration: none;
  display: inline-block;
}


.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px
}

.gap-30 {
  gap: 30px;
}

/* menu */
.menu-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #111;
  z-index: 9999;
  overflow-x: hidden;
  transition: 0.5s;
}

.menu-sidebar .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: #fff;
  background-color: #222;
}

.menu-sidebar .header h3 {
  margin: 0;
  font-size: 24px;
}

.menu-sidebar .header .close-btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 32px;
  cursor: pointer;
}

.menu-sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-sidebar nav li {
  padding: 15px;
}

.menu-sidebar nav a {
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.menu-sidebar.show {
  width: 300px;
}


.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}

@media screen and (max-width:768px) {
  .cart-button {
    background-color: var(--color-232f3e);
    color: white;
    font-size: 12px;
    padding: 2% 3%;
    border-radius: 15px;
  }

  .checkout-button {
    background-color: var(--color-232f3e);
    color: white;
    font-size: 12px;
    padding: 2% 4%;
    border-radius: 25px;
  }
}

@media screen and (min-width:768px) {
  .cart-button {
    background-color: var(--color-232f3e);
    color: white;
    font-size: 14px;
    padding: 1% 4%;
    border-radius: 25px;
  }

  .checkout-button {
    background-color: var(--color-232f3e);
    color: white;
    font-size: 12px;
    padding: 2% 4%;
    border-radius: 25px;
  }
}


.button:hover {
  background-color: var(--color-febd69);
  color: var(--color-131921);
}

/* Header Starts Here */
.header-top-strip {
  border-bottom: 1px solid var(--color-3b4149);
  border-color: var(--color-131921);
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.header-upper {
  border-bottom: 1px solid;

}

.header-bottom {
  background-color: var(--color-1c1c1b);
}

.header-upper .input-group-text {
  background-color: var(--color-febd69);
}

.header-bottom .menu-links a {
  color: var(--color-ffffff);
  font-size: 14px;
  line-height: 18px;
  font-weight: 50;
  letter-spacing: 0.3;
  text-transform: uppercase;
}

/* Mobile Responsive  */
/* Mobile Device  */
@media (max-width: 575.98px) {

  .header-bottom .menu-links a,
  .header-bottom .menu-links {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.1;
  }
}

@media (max-width: 575.98px) {
  .header-bottom .menu-links>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}

@media (max-width: 575.98px) {
  .header-bottom .menu-bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
  }
}

/* Mobile Device  */


/* LandScape Phones */
@media (min-width: 576px) and (max-width: 767.98px) {
  .header-bottom .menu-links a {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .header-bottom .menu-links>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 11px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .header-bottom .menu-bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 11px;
  }
}

/* LandScape Phones */


/* Tablets */
@media (min-width: 768px) and (max-width: 991.98px) {
  .header-bottom .menu-links a {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .header-bottom .menu-links>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .header-bottom .menu-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    gap: 14px;
  }
}

/* Tablets */


/* Desktops */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .header-bottom .menu-links a {
    font-size: 16px;
    letter-spacing: 0.3;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .header-bottom .menu-links>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .header-bottom .menu-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    gap: 15px;
  }
}

/* Desktops */
/* Large Desktops */
@media (min-width: 1200px) {
  .header-bottom .menu-links a {
    font-size: 16px;
    letter-spacing: 0.3;
  }
}

@media(min-width: 1200px) {
  .header-bottom .menu-links>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
}

@media (min-width: 1200px) {
  .header-bottom .menu-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
  }
}

/* Large Desktops */
/* Mobile Responsive  */




.header-bottom .dropdown button::before {
  contain:
}

.header-bottom .dropdown button:focus {
  box-shadow: none;
}

.header-bottom .dropdown-menu {
  background-color: var(--color-ffffff);
  width: 100%;
  transform: translate3d(0px, 33.5px, 0px) !important;
}

.header-bottom .dropdown-item {
  padding: 20px;
  margin-bottom: 1px;
  border-bottom: ;
}

.header-bottom .dropdown-item:hover {
  background-color: transparent;
  color: var(--color-febd69) !important;
}

.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

/* Header Ends Here */


/* Footer Starts Here */
.footer-bottom {
  background-color: var(--color-ffffff);
  font-size: 13px;
}

.footer-middle {
  margin-top: 10px;
  font-size: 13.6px;
}


@media (max-width: 575.98px) {
  .footer-middle {
    padding-top: 28px;
    padding-bottom: 5px;
  }
}

/* Small devices (landscape phones) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .footer-middle {
    padding-top: 28px;
    padding-bottom: 5px;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .footer-middle {
    padding-top: 32px;
    padding-bottom: 15px;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-middle {
    padding-top: 32px;
    padding-bottom: 15px;
  }
}

/* Extra large devices (large desktops) */
@media (min-width: 1200px) {
  .footer-middle {
    padding-top: 32px;
    padding-bottom: 15px;
  }
}

.footer-middle h4 {
  font-size: 14px;
  color: inherit;
  font-weight: inherit;
  text-transform: uppercase;
  letter-spacing: .2em;
}



.footer-middle .underline {
  text-decoration: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.3);
  padding-bottom: 0.1;
  margin-bottom: 0.1;
}

.footer-middle .underline2 {
  border-bottom: solid 2px rgba(0, 0, 0, 0.9);
  padding-bottom: 0.1;
  margin-bottom: 0.1;
}

.footer-middle {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.footer-middle ::placeholder {
  font-size: 13.6px;
  /* change the font size */
  font-family: inherit;
  /* change the font family */
  font-weight: normal;
  /* change the font weight, if needed */
  color: black;
  /* change the color, if needed */
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

/* Mobile Responsive  */
/* Mobile Device  */
@media (max-width: 575.98px) {

  /* Add your CSS styles here */
  .social-icons {
    justify-content: center;
    align-items: center;
  }

  .contact-mobile {
    text-align: center;
  }

  .footer-link {
    text-align: center;
  }
}


@media (min-width: 576px) and (max-width: 767.98px) {

  /* Add your CSS styles here */
  .social-icons {
    justify-content: center;
    align-items: center;
  }

  .footer-link {
    text-align: center;
  }
}

@media (min-width: 768px) {

  .contact-mobile h4,
  .footer-link h4 {
    pointer-events: none;
  }
}

@media screen and (max-width: 767.98px) {
  .link-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .link-container.open {
    max-height: 500px;
    /* Adjust the value to your desired maximum height */
    transition: max-height 0.3s ease-in-out;
  }

  .link-item {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .link-container.open .link-item {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
  }

  .link-container.open .link-item:nth-child(2) {
    transition-delay: 0.2s;
  }

  .link-container.open .link-item:nth-child(3) {
    transition-delay: 0.3s;
  }

  .link-container.open .link-item:nth-child(4) {
    transition-delay: 0.4s;
  }

}






/* Mobile Device  */

/* LandScape Phones */



/* LandScape Phones */
/* Tablets */
/* Tablets */
/* Desktops */
/* Desktops */
/* Large Desktops */
/* Large Desktops */
/* Mobile Responsive  */
/* Footer Ends Here */


/* Home Page Starts Here*/
.main-banner-content {
  top: 15%;
  left: 5%;
}

.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.main-banner-content h5 {
  font-size: 42px;
  line-height: 54px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}

.main-banner-content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}


.small-banner-content {
  top: 25%;
  left: 10%;
}

.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.small-banner-content h5 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}

.small-banner-content p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}

.small-banner {
  width: 49%;
}

.section-heading {
  text-align: center;
  font-family: Futura, sans-serif;
  font-weight: 500;
}

.review-heading {
  text-align: center;
  font-family: Futura, sans-serif;
  font-weight: 600;
}

/* home-wrapper 2 Starts Here */
.home-wrapper-2 {
  background-color: var(--color-ffffff);
}



/* home-wrapper 2 Ends Here*/

/* categories section starts here */
.categories {
  background-color: var(--color-ffffff);
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

.categories>div>div {
  width: 60%;
}

.categories>div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}

.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3),
.categories>div:nth-child(4) {
  border-bottom: 1px solid var(--color-ededed);
}

.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3) {
  border-right: 1px solid var(--color-ededed);
}

.categories>div:nth-child(5),
.categories>div:nth-child(6),
.categories>div:nth-child(7) {
  border-right: 1px solid var(--color-ededed);
}




/* categories ends  here */


/* ProductCard Starts Here */
.product-card {
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  /* border: 1px solid rgba(0, 0, 0, .15); */
  width: 100%;
  height: auto;
}

.product-img {
  width: 100%;
  height: auto;
}

.product-image {
  position: relative;
}

.product-image img {
  object-fit: contain;
}

.product-image img:last-child {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.product-card:hover .product-image img:first-child {
  display: none;
}

.product-card:hover .product-image img:last-child {
  display: block;
}

.product-card .product-image {
  height: 100%;
}

.product-card .product-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.product-card .product-image>img:last-child {
  display: none;
}

.product-card:hover .action-bar {
  right: 16px;
  opacity: 100;
  pointer-events: all;
}

/* .product-card .product-details h6 {
  color: var(--color-bf4800);
  font-size: 13px;
}  */



.product-card .product-details h5 {
  text-align: center;
  font-size: 16px;
  margin-top: 1%;
  margin-bottom: 0;
  color: var(--color-1c1c1b);
}

.product-card .product-details p.price {
  font-size: 13.6px;
  text-align: center;
  color: var(--color-777777) !important;
}

.product-card p.description {
  color: var(--color-777777);
  font-size: 13px;
  margin-right: 20px;
}

.action-bar {
  top: 13%;
  right: -23px;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
}

.wish-icon-box {
  top: 78.5%;
  right: 4.5%;
  z-index: 1;
  background-color: white;
  width: 11%;
  height: 8%;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add styles for small screens (less than 768px) */
@media (max-width: 767px) {
  .mobile-other {
    width: 100%;
  }

  .desktop-image {
    display: none;
  }

  .other-product-images {
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .image-container {
    display: inline-block;
    flex: 0 0 auto;
    width: 100px;
    /* Adjust the width of each image container as needed */
  }

  .selected {
    border: none !important;
  }

  .selected-size {
    border: 1px solid rgb(0, 0, 0, 0.7);
    /* Add a border or any other styling for the selected image */
  }
}

.selected-size {
  border: 2px solid rgb(0, 0, 0, 0.7) !important;
}

.single-product-size {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 14px;
  padding-right: 14px;
  border: 1px solid rgba(0, 0, 0, .2);
  font-family: "Futura";
  font-size: 14px;
  color: #3b4149
}

.single-product-size.disabled {
  position: relative;
  cursor: not-allowed;
  color: #e2e2db;
}

.single-product-size.disabled::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1.4px;
  background-color: rgba(0, 0, 0, .2);
  transform: translateY(-55%) rotate(-45deg);

}

.single-product-heading {
  text-align: center;
  font-family: "Futura", sans-serif;
  font-size: 19px;
  padding-bottom: 1.5%;
  text-transform: uppercase;
}

.single-product-data {
  text-align: center;
  padding-top: 1%;
  padding-bottom: 3%;
  color: var(--color-777777);
  font-size: 15px;
  font-weight: lighter;
}

.single-product-second-policy {
  border-right: 1px solid rgba(0, 0, 0, .15);
  padding-left: 4rem;
  padding-right: 4rem;
  border-left: 1px solid rgba(0, 0, 0, .15);
}

.single-product-second-policy-mobile {
  padding-left: 15%;
  padding-right: 15%;
}

.single-product-button {
  background-color: black;
  color: white;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  margin-left: 2px;
  margin-right: 2px;
  text-transform: uppercase;
}

.single-product-button-wishlist {
  background-color: white;
  color: black;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  margin-left: 2px;
  margin-right: 2px;
  text-transform: uppercase;
  border: 1px solid rgba(0, 0, 0, .15)
}


/* ProductCard Ends Here */


/* Special Product Starts Here */
.special-product-card {
  padding: 20px 10px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, .15)
}

.special-product-card .special-image {
  width: 100%;
  height: 100%
}



/* Special Product Ends Here */

/* Home Page Ends Here*/



/* Our Store */
.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 15px 15px 25px;
  border: 1px solid rgba(0, 0, 0, .15)
}

.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}



.filter-card ul,
.filter-card .form-check-label {
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}

.filter-card .form-check-input:focus {
  box-shadow: none;
  border-color: rgb(0, 0, 0, 0.25);
}

.filter-card .form-check-input:checked {
  background-color: var(--color-febd69);
  border-color: var(--color-febd69);

}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
  margin-top: 20px;
}

.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.colors li {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}

.random-products h5 {
  font-size: 14px;
  margin-bottom: 10px;
}

.random-products:first-child {
  border-bottom: 1px solid var(--color-ededed);
}

.filter-sort-grid {
  padding: 1%;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, .15)
}

.breadcrumb {
  /* border-bottom: 1px solid rgba(0, 0, 0, .15); */
  /* margin-top: 95px; */
  /* font-size: 26px; */
}

.grid img {
  width: 32px;
  height: 32px;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}

.gr-12 {
  width: 100%;
}

.gr-6 {
  width: 49%;
}

.gr-4 {
  width: 32%;
}

.gr-3 {
  width: 24%;
}


.gr-12 .product-card {
  display: flex;
  gap: 20px;
}

.gr-6 .product-image {
  width: 100%;
}

.gr-6 .product-image img {
  margin: auto;
  display: block;
}

.gr-12 .product-card .product-image {
  width: 270px;
}

.gr-12 .product-card .product-image {
  height: auto;
}

.gr-12 .action-bar {
  top: 17%;
}

/* Our Store Ends Here */



/* Contact page starts here */
.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
  gap: 15px;
}

.contact-inner-wrapper>div {
  width: 90%;
}

.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}

.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}


.contact-inner-wrapper ul {
  list-style: none;
}

.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  columns: var(--color-777777);
}

/* Contact page ends here */


/* Wishlist Starts Here  */
.wishlist-card .cross {
  top: 15px;
  right: 15px;
  width: 25px;
  padding: 3px;
  cursor: pointer;
}

.wishlist-card h5.title {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-1c1c1b);
  text-align: center;
}

.wishlist-card h6.price {
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
}


/* Wishlist Ends Here  */


/* authcard Starts Here  */
.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}

.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}

.auth-card .form-control {
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}

.auth-card a {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}

.auth-card p {
  font-size: 13px;
  color: var(--color-777777);
}

.signup {
  background-color: var(--color-febd69);
  color: var(--color-131921);
  border: none;
}

.signup:hover {
  background-color: var(--color-131921);
  color: white
}

/* authcard Ends Here  */


/* Policy Pages Starts Here  */
.policy {
  padding: 2%;
  background-color: white;
}


/* Policy Pages Ends Here  */



/* Main Product Page  */

.description-wrapper h4,
.reviews-wrapper h3 {
  font-size: 26px;
  color: var(--color-1c1c1b);
  margin-bottom: 2%;
}

.description-wrapper .area {
  border: 1px solid rgba(0, 0, 0, .15)
}

.description-wrapper p {
  font-size: 14px;
  color: var(--color-777777);
}

.review-inner-wrapper {
  background-color: white;
  padding: 4%;
  border: 1px solid rgba(0, 0, 0, .15)
}

.review-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 3%;
}

.review-head h4 {
  font-size: 18px;
  color: var(--color-1c1c1b);
}


.review-head p,
.review-head a {
  font-size: 14px;
  color: var(--color-777777);
}

.review-form h4 {
  size: 16px;
  color: var(--color-777777);
}

.review-form {
  border-bottom: rgba(0, 0, 0, 0.1);
}

.review p {
  font-size: 14px;
  color: var(--color-777777);
}

.review:not(:last-child) {
  border-bottom: rgba(0, 0, 0, 0.1);
}

.main-product-image {
  width: 400;
  height: 600;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, .15)
}

.main-product-image>div {
  border: 1px solid rgb(0, 0, 0, 0.25);
}

.main-product-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.other-product-images {
  background-color: white;
  /* padding: 20px; */
  padding-top: 6px;
}

.selected {
  border: 2px solid rgb(0, 0, 0, 0.7);
  /* border: 2px solid red !important; */
  /* Replace with your desired border style */
}

.other-product-images>div {
  /* padding: 40px; */
  width: 100%;
  /* border: 1.8px solid rgb(0, 0, 0, 0.7); */

}

.main-product-image {
  left: 60px !important;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}

.main-product-details {
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px;
  /* border: 1px solid rgba(0, 0, 0, .15) */
}


.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25)
}

.main-product-details h3.title {
  font-size: 30.6px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Futura";
  color: var(--color-1c1c1b);
  margin-bottom: 5px;
}

.main-product-details p.price {
  font-size: 14.6px;
  font-family: "Futura";
  line-height: 20px;
  font-weight: 500;
  /* margin-bottom: px; */
}

.main-product-details a.review-btn,
.t-review {
  color: var(--color-777777);
  font-size: 13px;
}

.main-product-details .shipping {
  font-size: 13.6px;
  color: var(--color-777777);
}

.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  font-weight: 600;
  font-family: "Futura";
}

.product-data {
  font-size: 13px;
  color: var(--color-777777);
}

.product-data-2 {
  font-size: 17.5px;
  color: rgb(98, 89, 89);
}

.main-product-details a {
  font-size: 14px;
  color: var(--color-777777);
}




/* Main Product Page Ends Here  */

/* Cart Page Starts Here  */

.cart-header,
.cart-data {
  border-bottom: 1px solid #eaeaea;
}

.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}


.cart-col-1 {
  width: 40%;
}

.cart-col-1 p {
  font-size: 14px;
  color: var(--color-777777);
}

.cart-col-2 {
  width: 10%;
}

.cart-col-3 {
  width: 15%;
}

.cart-col-4 {
  width: 15%;
}

.cart-data h5.price {
  font-size: 14px;
  color: var(--color-1c1c1b);
}


/* Cart Page Ends Here  */

/* Shipping Page Starts Here */
.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}

.total-price {
  font-size: 16px;
  color: var(--color-777777);
}

/* Shipping Page Ends Here */


/* No wishlist Products Data   */


@media screen and (max-width: 575.98px) {
  .no-wish-contents h2 {
    font-size: 22px;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }

  .no-wish-contents button {
    font-size: 20px !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
  }

  .no-wish-contents h3 {
    font-size: 18px !important;
    color: var(--color-777777);
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    text-align: center;
  }
}


@media (min-width: 576px) and (max-width: 767.98px) {
  .no-wish-contents h2 {
    font-size: 24px;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .no-wish-contents button {
    font-size: 21px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  .no-wish-contents h2 {
    font-size: 24px;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .no-wish-contents button {
    font-size: 22px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .no-wish-contents h2 {
    font-size: 26px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .no-wish-contents button {
    font-size: 24px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}


@media (min-width: 1200px) {
  .no-wish-contents h2 {
    font-size: 28px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .no-wish-contents button {
    font-size: 26px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

.no-wish-contents>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-wish-contents h2 {
  text-align: center;

}


.no-wish-contents h3 {
  font-size: 22px;
  color: var(--color-777777);
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
}


.no-wish-contents button {
  text-align: center;
  color: white;
  background-color: rgb(246, 199, 107);
}

.no-wish-contents .order-empty {
  margin-top: 5%;
  text-align: center;
  font-size: 21px;
  color: white;
  background-color: #daf183;
  padding-left: 13px;
  padding-right: 13px;
}


/* No wishlist Products Data   */



/* my orders  */
.my-orders h6 {
  font-size: 22px;
  color: whitesmoke;
}

/* For WebKit-based browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

@media screen and (max-width: 575.98px) {
  .my-orders h5 {
    font-size: .7rem
  }

  .my-orders p {
    font-size: .7rem
  }

  .my-orders h6 {
    font-size: .7rem
  }
}


@media (min-width: 576px) and (max-width: 767.98px) {
  .my-orders p {
    font-size: .8rem
  }

  .my-orders h6 {
    font-size: .8rem
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .my-orders h5 {
    font-size: 1.1rem
  }

  .my-orders p {
    font-size: 1rem
  }

  .my-orders h6 {
    font-size: 1.1rem
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-orders h5 {
    font-size: 1.4rem
  }

  .my-orders p {
    font-size: 1.3rem
  }

  .my-orders h6 {
    font-size: 1.4rem
  }
}


@media (min-width: 1200px) {
  .my-orders h5 {
    font-size: 1.5rem
  }

  .my-orders h6 {
    font-size: 1.5rem
  }

  .my-orders p {
    font-size: 1.5rem
  }
}






/* my orders  */


/* Update Profile   */
.update-profile .form-label {
  font-size: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.update-profile h3 {
  font-size: 26px;
}



/* Update Profile   */



/* action error     */

.action-error {
  text-align: center;
  color: red
}


/* action error     */

/* not found page  */


.not-found h3 {
  font-size: 26px;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 2px;

}


.not-found h2 {
  font-size: 22px;
  color: var(--color-777777);
  margin-top: 2px;
  margin-bottom: 2px;
}


.not-found button {
  margin-top: 5px;
  /* margin-bottom: 3px; */
  text-align: center;
  font-size: 21px;
  color: white;
  background-color: rgb(37, 37, 157);
  padding-left: 13px;
  padding-right: 13px;

}

/* not found page  */


/* Apply Coupon  */
.coupon-area .coupon-input {
  border: 1px solid black;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.coupon-area .apply-button {
  background-color: rgb(23, 19, 2);
  color: white;
  border: 1px solid black;
  padding-left: 4px;
  padding-right: 4px;

}

.coupon-message {
  color: red;
  font-size: 14px;
}

.coupon-success {
  color: green;
  font-size: 14px;
}

/* Apply Coupon  */


.checkout-left-data .website-name {
  font-size: 29px;
}




/* Carousel  */

.review-carousel .carousel-control-prev-icon {
  filter: invert(100%);
}

.review-carousel .carousel-control-next-icon {
  filter: invert(100%);
}

.review-item {
  text-align: center;
  padding-bottom: 2px;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Adjust the height as needed */
}

.review-image {
  max-width: 100%;
  max-height: 100%;
}


.typeahead-animation-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.typeahead-animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.typeahead-animation-exit {
  opacity: 1;
  transform: translateY(0);
}

.typeahead-animation-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}



.success {
  color: green;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}



/* Adding Shimmer Here  */
.shimmer-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-line-container {
  width: 50%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  margin-bottom: 15px;
}

.loading-line {
  width: 100%;
  height: 3px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

.loading-line:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  /* Adjust the width to control the animation speed */
  height: 100%;
  background-color: #fff;
  animation: loading-line-animation 1.5s infinite;
  animation-timing-function: linear;
  /* transform-origin: 50% 50%; */
}

@keyframes loading-line-animation {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(50%);
  }
}
.hero {
  background-color: var(--overlay);
  color: #fdfdfd;
  height: 93.9vh;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.hero::before {
  content: '';

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 0; */
}

.carousel-control-prev,
.carousel-control-next {
  width: 8%;
  max-width: 100px;
  /* adjust this value as needed */
  height: 100%;
  top: 0;
}


.hero .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
  padding: 1rem;
  opacity: 99%;
}

.hero .content p:first-child {
  font-style: italic;
  font-size: .9rem;
}

.hero .content p:nth-child(2) {
  font-size: 1.9rem;
}

.hero .content p:nth-child(3) {
  font-size: 5rem;
  font-weight: 700;
}


.hero .content p:nth-child(4) {
  font-size: 2.2rem;
  font-style: italic;
}

.hero .button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  width: 30%;
  background-color: var(--secondary-color);
  border: 0.5px solid var(--secondary-color);
  color: #333;
}

.hero .button:hover {
  background-color: var(--secondary-dark);
  border: 1px solid var(--secondary-dark);
}

@media screen and (max-width: 575.98px) {



  .hero .content p:first-child {
    font-style: italic;
    font-size: .9rem;
  }

  .hero .content p:nth-child(2) {
    font-size: 1.3rem;
  }

  .hero .content p:nth-child(3) {
    font-size: 1.8rem;
  }

  .hero .content p:nth-child(4) {
    font-size: 1.2rem;
  }

  .hero .button {
    width: 45%;
    height: 38px
  }
}


@media screen and (max-width: 575.98px) {

  .first-carousel .carousel-control-prev,
  .first-carousel .carousel-control-next {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {



  .hero .content p:first-child {
    font-style: italic;
    font-size: 1rem;
  }

  .hero .content p:nth-child(2) {
    font-size: 1.5rem;
  }

  .hero .content p:nth-child(3) {
    font-size: 2rem;
  }

  .hero .content p:nth-child(4) {
    font-size: 1.4rem;
  }

  .hero .button {
    width: 50%;
    height: 38px
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hero .content p:first-child {
    font-style: italic;
    font-size: 1.25rem;
  }

  .hero .content p:nth-child(2) {
    font-size: 1.6rem;
  }

  .hero .content p:nth-child(3) {
    font-size: 2.1rem;
  }

  .hero .content p:nth-child(4) {
    font-size: 1.5rem;
  }

  .hero .button {
    width: 40%;
    height: 40px
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hero .content p:first-child {
    font-style: italic;
    font-size: 1.6rem;
  }

  .hero .content p:nth-child(2) {
    font-size: 2.1rem;
  }

  .hero .content p:nth-child(3) {
    font-size: 2.6rem;
  }

  .hero .content p:nth-child(4) {
    font-size: 1.8rem;
  }

  .hero .button {
    width: 35%;
    height: 42px
  }
}

@media (min-width: 1200px) {
  .hero .content p:first-child {
    font-style: italic;
    font-size: 1.8rem;
  }

  .hero .content p:nth-child(2) {
    font-size: 2.4rem;
  }

  .hero .content p:nth-child(3) {
    font-size: 3.2rem;
  }

  .hero .content p:nth-child(4) {
    font-size: 2rem;
  }

  .hero .button {
    width: 40;
    height: 46px
  }
}



/* .loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;

} */
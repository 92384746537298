.header {
  position: fixed;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  transition: .3s ease-in;
  overflow: -moz-hidden-unscrollable;
}

@media screen and (max-width: 575.98px) {
  .lower-cross {
    margin-top: 16%;
  }
}


@media (min-width: 576px) and (max-width: 767.98px) {
  .lower-cross {
    margin-top: 15%
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .lower-cross {
    margin-top: 13%
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .lower-cross {
    margin-top: 11%
  }
}


@media (min-width: 1200px) {
  .lower-cross {
    margin-top: 9.5%
  }
}


.header2 {
  position: sticky;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  transition: .3s ease-in;
  overflow: -moz-hidden-unscrollable;
  background-color: white !important;
  border-bottom: 1px solid rgb(0, 0, 0, 0.08);
}

/* not home header  */


/* 
input.rbt-input-main.form-control.rbt-input.my-menu-class-dark::placeholder {
  color: rgba(255, 255, 255, 0.9)
} */

.typing-white::placeholder {
  color: white !important;
}

.header-bg {
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

/* Header Icons Color */
.upper {
  color: white;
}

.lower {
  color: black;
}



.upper-header-logo {
  filter: brightness(0) invert(1);
}

.lower-header-logo {
  filter: brightness(0) invert(0);
}

/* Header Icons Color */



/* test  */


.my-menu-class-dark .dropdown-item {
  color: white;
}

.search-bar .form-control {
  width: 97.5%;
}

.my-menu-class-dark .show {
  color: white;
}

/* test  */









.header .navbar {
  margin: 0px 20px;
  height: 100%;
  padding: 0 1rem;
}

.header .nav-menu a {
  color: #000000;
}

.header .navbar img {
  width: 150px;
  height: auto;
}

.header .nav-menu {
  display: flex;
}

.header .nav-item {
  padding: 10px;
  font-weight: 500;
}

.header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
  display: none;
}